<template>
  <div class="ibox" ref="companyInfo">
    <div class="ibox-title">
      <h4>{{$t('company.info.title')}}</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content">
      <ul class="list-group clear-list">
        <li class="list-group-item fist-item">
            {{$t('company.info.name')}}
            <span class="float-right">{{company.name}}</span>
        </li>
        <li v-if="company.address" class="list-group-item">
            {{$t('address.form.addressLine')}}
            <span class="float-right text-right">
              {{company.address.addressLines[0]}}
              <span v-for="index in company.address.addressLines.length-1" :key="index">
                <br/> {{company.address.addressLines[index]}}
              </span>
            </span>
        </li>
        <li v-if="company.address" class="list-group-item">
            {{$t('address.form.postalCode')}}
            <span class="float-right">{{company.address.postalCode}}</span>
        </li>
        <li v-if="company.address" class="list-group-item">
            {{$t('address.form.city')}}
            <span class="float-right">{{company.address.city}}</span>
        </li>
        <li v-if="company.address" class="list-group-item">
            {{$t('address.form.country')}}
            <span class="float-right">{{$t("COUNTRIES."+company.address.countryCode.toUpperCase())}}</span>
        </li>
        <li v-if="company.vatNumber" class="list-group-item">
            {{$t('company.info.vatNumber')}}
            <span class="float-right">{{company.vatNumber}}</span>
        </li>
        <li v-if="company.identifiers && company.identifiers.siret" class="list-group-item">
            {{$t('company.identifiers.siret')}}
            <span class="float-right">{{company.identifiers.siret}}</span>
        </li>
        <li v-if="company.size" class="list-group-item">
            {{$t('company.info.nbOfEmployees')}}
            <span class="float-right">{{$t('nbOfEmployees.'+company.size)}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';  
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery'; 

@Component({
  components: {}
})
export default class Info extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly company!: any

  created() {}

  mounted() {
    enableIbox(this.$refs.companyInfo as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.companyInfo as HTMLElement);
  }

}
</script>